export enum TableHeaders {
	ProductOrder = "PO",
	ItemNumber = "Item No.",
	PackageNumber = "Package No.",
	Name = "Name",
	BookingNumber = "Booking No.",
	QuantityBox = "Order QTY Box",
	QuantityBoxEntered = "Packing list QTY Box",
	QuantityBoxDeviation = "Deviation QTY Box",
	QuantityItem = "Order QTY Pcs",
	QuantityItemEntered = "Packing list QTY Pcs",
	QuantityItemDeviation = "Deviation QTY Pcs",
	Backorder = "Backorder",
	WeightNetto = "Weight Netto (kg)",
	WeightGross = "Weight Gross (kg)",
	SkuDetails = "SKU Details",
	LocationCode = "Location Code",
	Status = "Status",
}
