import * as React from "react";
import {
	ColumnDef,
	useReactTable,
	getCoreRowModel,
	RowData,
	FilterFn,
	Row,
	getFilteredRowModel,
} from "@tanstack/react-table";

import { Checkbox } from "../common/components/Table/Chekbox";
import TableComponent from "../common/components/Table/TableComponent";
import Button from "../common/components/Button";
import { useNavigate } from "react-router-dom";
import RoutePaths from "../common/enums/RoutePaths";
import { TableData } from "../common/interfaces/TableData";
import { useAuthUser, useSignOut } from "react-auth-kit";
import Select, {
	ActionMeta,
	GroupBase,
	MultiValue,
	OptionsOrGroups,
} from "react-select";
import { FilterOptionOption } from "react-select/dist/declarations/src/filters";
import { useStorageState } from "../common/customHooks/useStorageState";
import { OptionType, convertToOptions } from "../common/helperfunctions";
import { TableHeaders } from "../common/enums/TableHeaders";

declare module "@tanstack/react-table" {
	interface TableMeta<TData extends RowData> {
		updateData: (
			rowIndex: number,
			columnId: string,
			value: unknown
		) => void;
	}
}

export interface props {
	baseUrl: string;
	filterList: TableData[];
	setSelectedOrders: React.Dispatch<React.SetStateAction<string[]>>;
	loading: boolean;
	error: string;
}

const Home: React.FC<props> = ({
	baseUrl,
	filterList,
	setSelectedOrders,
	loading,
	error,
}) => {
	const auth = useAuthUser();
	const signOut = useSignOut();

	const [productOrderFilter, setProductOrderFilter] = useStorageState<
		string[]
	>(window.sessionStorage, auth()?.type + "_POfilter", []);

	React.useEffect(() => {
		table?.getColumn("purchaseOrderId")?.setFilterValue(productOrderFilter);
	}, [productOrderFilter]);

	const selectFilterFn: FilterFn<any> = (
		row: Row<TableData>,
		columnId,
		value,
		addMeta
	) => {
		if (value === undefined || value.length === 0) {
			return true;
		}
		if (row.original.purchaseOrderId === undefined) {
			return true;
		}
		return value.includes(row.original?.purchaseOrderId);
	};

	const defaultColumns: ColumnDef<TableData>[] = [
		{
			id: "select",
			accessorKey: "select",
			header: ({ table }) => (
				<Checkbox
					{...{
						checked: table.getIsAllRowsSelected(),
						indeterminate: table.getIsSomeRowsSelected(),
						onChange: table.getToggleAllRowsSelectedHandler(),
					}}
				/>
			),
			cell: ({ row }) => (
				<Checkbox
					{...{
						checked: row.getIsSelected(),
						indeterminate: row.getIsSomeSelected(),
						onChange: row.getToggleSelectedHandler(),
						disabled: !row.getCanSelect(),
					}}
				/>
			),
		},
		{
			id: "purchaseOrderId",
			header: TableHeaders.ProductOrder,
			footer: TableHeaders.ProductOrder,
			accessorKey: "purchaseOrderId",
			cell: (info) => info.renderValue(),
			enableColumnFilter: true,
			filterFn: selectFilterFn,
		},
		{
			id: "itemNo",
			header: TableHeaders.ItemNumber,
			footer: TableHeaders.ItemNumber,
			accessorKey: "itemNo",
			cell: (info) => info.renderValue(),
		},
		{
			id: "locationCode",
			header: TableHeaders.LocationCode,
			footer: TableHeaders.LocationCode,
			accessorKey: "locationCode",
			cell: (info) => info.renderValue(),
		},
		{
			id: "holdDescription",
			header: TableHeaders.Status,
			footer: TableHeaders.Status,
			accessorKey: "holdDescription",
			cell: (info) => info.renderValue(),
		},
	];
	const navigate = useNavigate();

	const [rowSelection, setRowSelection] = React.useState({});
	const columns = React.useMemo<typeof defaultColumns>(
		() => defaultColumns,
		[]
	);

	const handleFilterChange = (filterValues: MultiValue<OptionType>) => {
		const values = filterValues.map((filterValue) => filterValue.value);
		if (values === undefined) {
			return;
		}
		setProductOrderFilter(values);
	};

	const handleSelectClick = () => {
		const selectedrows = table
			.getSelectedRowModel()
			.flatRows.map((row) => row._valuesCache);

		setSelectedOrders(
			selectedrows.map((row) => row.purchaseOrderId) as string[]
		);
		navigate(RoutePaths.Edit);
	};
	const handleLogout = () => {
		signOut();
	};

	const options = convertToOptions(filterList);

	const table = useReactTable({
		data: filterList,
		columns: columns,
		state: {
			rowSelection,
		},
		enableRowSelection: (row) => row.original.holdDescription == null,
		onRowSelectionChange: setRowSelection,
		getCoreRowModel: getCoreRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
	});
	return (
		<>
			<div className="flex justify-between mb-[10px]">
				<div className="flex gap-2">
					<Button
						onClick={handleSelectClick}
						text={"Select"}
						disabled={Object.keys(rowSelection).length === 0}
						className="flex justify-start"
					/>
					<div
						className={
							(error !== "" ? "visible" : "invisible") +
							" py-1 px-3 rounded bg-red-300/25"
						}
					>
						{error}
					</div>
				</div>

				<div className="flex">
					<Button
						onClick={handleLogout}
						text={"Sign out"}
						className="flex "
					/>
				</div>
			</div>
			<div className="flex mb-[10px] flex-wrap">
				<div className="pr-2">
					<Select
						placeholder="PO Numbers"
						options={options}
						isMulti={true}
						closeMenuOnSelect={false}
						styles={{
							control: (base) => ({
								...base,
								width: 400,
								marginBottom: 24,
								borderRadius: 0,
							}),
						}}
						onChange={(
							value: MultiValue<OptionType>,
							actionMeta: ActionMeta<OptionType>
						) => handleFilterChange(value)}
						value={productOrderFilter.map((savedfilter) => {
							return { value: savedfilter, label: savedfilter };
						})}
					/>
				</div>
			</div>
			<TableComponent
				table={table}
				loading={loading}
				headerCount={defaultColumns.length}
				customHeight="h-[calc(100vh-110px)] oneLine:h-[calc(100vh-144px)]"
			/>
		</>
	);
};
export default Home;
