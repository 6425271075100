import { Row, Table } from "@tanstack/react-table";
import { TableData } from "./interfaces/TableData";

export interface OptionType {
	value: string;
	label: string;
}

export const convertBooleanToStringValue = (booleanValue: boolean) => {
	return booleanValue ? "YES" : "NO";
};

export const populateUndefinedvalues = (fetchedFormattedData: TableData[]) => {
	fetchedFormattedData.forEach((fetchedRow) => {
		fetchedRow.bookingNo ??= "";
		fetchedRow.packageNo ??= "";
		fetchedRow.quantityBoxEntered ??= 0;
		fetchedRow.quantityItemEntered ??= 0;
		fetchedRow.weightNetto ??= 0;
		fetchedRow.weightGross ??= 0;
	});
};

export const updateStoredDataWithFetchedData = (
	fetchedFormattedData: TableData[],
	storedData: TableData[]
) => {
	fetchedFormattedData.forEach((fetchedRow) => {
		let matchedStoredRow = storedData.find(
			(storedRow) =>
				storedRow.purchaseOrderId === fetchedRow.purchaseOrderId &&
				storedRow.lineNo === fetchedRow.lineNo
		);
		if (matchedStoredRow) {
			fetchedRow.bookingNo = matchedStoredRow.bookingNo;
			fetchedRow.packageNo = matchedStoredRow.packageNo;
			fetchedRow.quantityBoxEntered = matchedStoredRow.quantityBoxEntered;
			fetchedRow.quantityItemEntered =
				matchedStoredRow.quantityItemEntered;
			fetchedRow.backorder = matchedStoredRow.backorder;
			fetchedRow.weightNetto = matchedStoredRow.weightNetto;
			fetchedRow.weightGross = matchedStoredRow.weightGross;
		}
	});
};

export const calculateTotalBoxQuantities = (
	table: Table<TableData>
): number | undefined => {
	const rows = table.getRowModel().rows;
	const DECIMALS: number = 2;
	const quantityBoxEntered = rows.map((row) => {
		if (row.original.quantityBoxEntered !== undefined) {
			return row.original.quantityBoxEntered;
		}
	});
	if (quantityBoxEntered === undefined) {
		return;
	}
	const total = quantityBoxEntered.reduce(
		(accumlator, quantityBoxEntered) => {
			return accumlator! + (quantityBoxEntered || 0);
		},
		0
	);
	if (total === undefined) {
		return;
	}
	return parseFloat(total?.toFixed(DECIMALS));
};

export const calculateTotalItemQuantities = (
	table: Table<TableData>
): number | undefined => {
	const rows = table.getRowModel().rows;
	const quantityItemEntered = rows.map((row) => {
		if (row.original.quantityItemEntered !== undefined) {
			return row.original.quantityItemEntered;
		}
	});
	if (quantityItemEntered === undefined) {
		return;
	}
	const total = quantityItemEntered.reduce(
		(accumlator, quantityItemEntered) => {
			return accumlator! + (quantityItemEntered || 0);
		},
		0
	);
	return total;
};

export const getListOfUniqueObjectsByKey = (
	key: keyof TableData,
	storedData: TableData[]
): TableData[] => {
	return [...new Map(storedData.map((row) => [row[key], row])).values()];
};

// after introducing a booking no field above the table, we are no longer checking booking no. as all rows in a filtered set, gets a booking no.
export const rowIsEdited = (row: TableData): boolean => {
	return (
		row.quantityBoxEntered !== 0 ||
		row.packageNo !== "" ||
		row.quantityItemEntered !== 0 ||
		row.backorder !== undefined ||
		row.weightNetto !== 0 ||
		row.weightGross !== 0
	);
};

export const removeRowsFromDataset = (
	dataset: TableData[],
	rowsToRemove: TableData[]
): TableData[] => {
	return dataset.filter((dataSetRow) => {
		return !rowsToRemove.some((rowToRemove) => {
			return (
				dataSetRow.purchaseOrderId === rowToRemove.purchaseOrderId &&
				dataSetRow.lineNo === rowToRemove.lineNo
			);
		});
	});
};

export const getUniqueItemNumbers = (
	storedData: TableData[],
	filteredProductOrders: string[]
) => {
	const uniqueItemNumbers: string[] = [];
	const list: TableData[] = storedData.filter((row) => {
		if (row.purchaseOrderId === undefined) {
			return false;
		}
		return filteredProductOrders.includes(row.purchaseOrderId);
	});
	list.forEach((row) => {
		if (row.itemNo === undefined) {
			return;
		}
		if (!uniqueItemNumbers.includes(row.itemNo)) {
			uniqueItemNumbers.push(row.itemNo);
		}
	});
	return uniqueItemNumbers;
};

export const hasMultipleOrdersSelected = (
	selectedOrders: string[]
): boolean => {
	return selectedOrders.length > 1;
};

export const editTableField = (
	row: TableData,
	fieldName: keyof TableData,
	value: TableData[keyof TableData]
) => {
	return { ...row, [fieldName]: value };
};

export const convertToOptions = (list: TableData[]): OptionType[] => {
	const options = list.map((row) => {
		if (row.purchaseOrderId !== undefined) {
			return { value: row.purchaseOrderId, label: row.purchaseOrderId };
		}
		return { value: "", label: "" };
	});
	return options;
};
