import * as React from "react";

export function Checkbox({
	indeterminate,
	className = "",
	...rest
}: { indeterminate?: boolean } & React.HTMLProps<HTMLInputElement>) {
	const ref = React.useRef<HTMLInputElement>(null!);

	React.useEffect(() => {
		if (typeof indeterminate === "boolean") {
			ref.current.indeterminate = !rest.checked && indeterminate;
		}
	}, [ref, indeterminate]);

	return (
		<input
			type="checkbox"
			ref={ref}
			className={
				rest.disabled
					? "cursor-default "
					: "cursor-pointer " + className
			}
			{...rest}
		/>
	);
}
